import { useMutation } from '@apollo/client';
import { ChangeEvent, FormEvent, useState } from 'react';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import TextInput from 'components/InputFields/TextInput/TextInput';
import Toggle from 'components/InputFields/Toggle/Toggle';
import Modal from 'components/Modals/Modal/Modal';

import { trackWishListCreated } from 'lib/analytics/wishlist';
import Logger from 'lib/utils/Logger';

import { GetBookmarkListsDocument } from 'types/generated/api';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './CreateBookmarkListModal.module.scss';

import { CREATE_BOOKMARK_LIST_MUTATION } from '../BookmarkLists.gql';

type CreateBookmarkListModalProps = {
  isOpen: boolean;
  onCloseClick: () => void;
  onCreateBookmarkList?: (bookmarkListId: string) => void;
};

const ON_SUCCESS_CLOSE_TIME_OUT_DURATION = 1000;
const ERROR_MESSAGE = 'Sorry, something went wrong. Please try again.';

const CreateBookmarkListModal = ({
  isOpen,
  onCloseClick,
  onCreateBookmarkList,
}: CreateBookmarkListModalProps) => {
  const [bookmarkListName, setBookmarkListName] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const handleBookmarkListNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setBookmarkListName(event.target.value);
  };

  const [createBookmarkList, { error, loading }] = useMutation(
    CREATE_BOOKMARK_LIST_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetBookmarkListsDocument,
        },
      ],
      variables: { input: { name: bookmarkListName, public: isPublic } },
    }
  );

  if (error) {
    Logger.error('Something went wrong creating a collection', error);
    setErrorMessage(ERROR_MESSAGE);
  }

  const handleCreateBookmarkList = async (event: FormEvent) => {
    try {
      event.preventDefault();

      if (!bookmarkListName) {
        setErrorMessage('Please enter a collection name');
        return;
      }

      setErrorMessage('');
      const result = await createBookmarkList();
      const bookmarkListId = result?.data?.createBookmarkList?.bookmarkList?.id;
      trackWishListCreated(bookmarkListId);
      setIsSuccess(true);
      onCreateBookmarkList?.(bookmarkListId);

      setTimeout(() => {
        onCloseClick();
        setIsSuccess(false);
        setBookmarkListName('');
      }, ON_SUCCESS_CLOSE_TIME_OUT_DURATION);
    } catch (handlerError) {
      Logger.error('Could not create collection', handlerError);
    }
  };

  return (
    <Modal
      className={styles.rootModal}
      hideDefaultHeader
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      overlayClassName={styles.overlayStyling}
    >
      <div className={styles.headerContainer}>
        <h1 className={styles.headerText}>Create a Collection</h1>
        <TextButton onClick={onCloseClick}>
          <CloseIcon className={styles.closeIcon} />
        </TextButton>
      </div>
      <form onSubmit={handleCreateBookmarkList}>
        <div className={styles.inputLabel} id="inputlabel">
          Collection Name<sup>*</sup>
        </div>
        <TextInput
          aria-labelledby="inputlabel"
          aria-required="true"
          // TODO: NEXTJS12 - Ignore this a11y error.  Do we need autofocus?
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={styles.textInput}
          onChange={handleBookmarkListNameChange}
          value={bookmarkListName}
        />
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
        <Toggle
          className={styles.toggleInput}
          isChecked={isPublic}
          labelChecked="Public"
          labelUnchecked="Private"
          onChange={() => {
            setIsPublic(!isPublic);
          }}
        />
        <div className={styles.publicCollectionText}>
          Public collections can be shared and viewed by others. If you’d like
          to share this collection, keep it public.
        </div>
        <PrimaryButton
          className={styles.button}
          isLoading={loading}
          isSuccess={isSuccess}
          onClick={handleCreateBookmarkList}
          size={BUTTON_SIZE.MEDIUM}
          text={isSuccess ? '' : 'Create Collection'}
          type="submit"
        />
        {isSuccess && (
          <div aria-live="polite" className="visuallyHidden" role="status">
            Collection Created
          </div>
        )}
        <SecondaryButton
          className={styles.cancelButton}
          onClick={onCloseClick}
          size={BUTTON_SIZE.MEDIUM}
          type="button"
        >
          Cancel
        </SecondaryButton>
      </form>
    </Modal>
  );
};

export default CreateBookmarkListModal;
