import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import Logger from 'lib/utils/Logger';

import {
  ProductOption,
  ProductOptionTypeEnum,
  SelectedOption,
} from 'data/graphql/types';
import { BookmarkByProductSid } from 'types/app';

// Returns all matching bookmarks for the selectedOption(s) and number
// of product option dimensions
export const getBookmarksBySelectedOptions = (
  currentBookmarksByProductSid: BookmarkByProductSid[],
  selectedOptions: SelectedOption[],
  productOptions: ProductOption[] = []
) => {
  const matchedBookmarks: BookmarkByProductSid[] = [];
  try {
    if (isEmpty(currentBookmarksByProductSid)) {
      return matchedBookmarks;
    }

    // get color and string option names if they exist
    const productOptionColorName = productOptions?.find(
      productOption => productOption.type === ProductOptionTypeEnum.Colors
    )?.name;
    const productOptionStringName = productOptions?.find(
      productOption => productOption.type === ProductOptionTypeEnum.Sizes
    )?.name;

    for (const bookmarkByProductSid of currentBookmarksByProductSid) {
      // Removes typename to properly match intersection
      const bookmarkSelectedOptions = bookmarkByProductSid.selectedOptions.map(
        selectedOption => omit(selectedOption, '__typename')
      );

      const intersection = intersectionWith(
        bookmarkSelectedOptions,
        selectedOptions,
        isEqual
      );

      // bookmark matching to a user's selected options must fall under one of these criteria:
      // 1) Empty as some products have no options
      // 2) Bookmark contains matching color
      // 3) Bookmark contains matching string option, and variant has one dimension
      const matchedZeroProductDimensionForVariant =
        isEmpty(selectedOptions) &&
        isEmpty(bookmarkSelectedOptions) &&
        productOptions.length === 0;

      const matchedColorSelectedOption = intersection.some(
        intersectionOption => intersectionOption.name === productOptionColorName
      );

      const matchedStringSelectedOption =
        productOptionStringName &&
        productOptions.length === 1 &&
        selectedOptions.length === 1;

      if (
        matchedZeroProductDimensionForVariant ||
        matchedColorSelectedOption ||
        matchedStringSelectedOption
      ) {
        matchedBookmarks.push(bookmarkByProductSid);
      }
    }
  } catch (error) {
    Logger.error(
      'Something went wrong running getBookmarksBySelectedOptions',
      error
    );
  }

  return matchedBookmarks;
};
