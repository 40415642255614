import toInteger from 'lodash/toInteger';
import { ReactChild, ReactElement } from 'react';

import { round } from 'lib/formatters';

import EmptyStar from 'assets/icons/ic-star-false.inline.svg';
import HalfStar from 'assets/icons/ic-star-half.inline.svg';
import FullStar from 'assets/icons/ic-star-true.inline.svg';

import styles from './StarRating.module.scss';

type StarRatingProps = {
  children?: ReactChild;
  numStars: number;
  optionalClassOverride?: string;
};

const TOTAL_STARS = 5;

const StarRating = ({
  children,
  numStars,
  optionalClassOverride,
}: StarRatingProps): ReactElement => {
  const fullStarsNumber = toInteger(numStars);
  const remainder = numStars % fullStarsNumber;
  const remainderRounded = round(remainder, 0.5);

  let extraStar = null;
  if (remainderRounded === 1) {
    extraStar = <FullStar className={styles.icon} key={numStars} />;
  } else if (remainderRounded === 0.5) {
    extraStar = <HalfStar className={styles.icon} key={numStars} />;
  }

  const arrayLength =
    TOTAL_STARS - fullStarsNumber - (extraStar === null ? 0 : 1);
  const renderEmptyStars = [...Array(arrayLength)].map((_, i) => (
    <EmptyStar className={styles.icon} key={`EmptyStar-${i}`} />
  ));

  return (
    <div
      className={
        optionalClassOverride ? optionalClassOverride : styles.container
      }
    >
      {[...Array(fullStarsNumber)].map((_, i) => (
        <FullStar className={styles.icon} key={`FullStar-${i}`} />
      ))}
      {extraStar}
      {renderEmptyStars}
      {children}
    </div>
  );
};

export default StarRating;
