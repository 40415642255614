import BadgeLoyaltyIcon from 'assets/icons/ic-badge-loyalty.inline.svg';

import styles from './VIPBrandBadge.module.scss';

export const VIPBrandBadge = () => {
  return (
    <div className={styles.vipBadge}>
      <BadgeLoyaltyIcon className={styles.icon} />
    </div>
  );
};
