import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import { useState } from 'react';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import SpinnerInlineCSS from 'components/SpinnerInlineCSS/SpinnerInlineCSS';

import { generateImageSrcAttributes } from 'lib/image';
import Logger from 'lib/utils/Logger';

import { SelectedOption } from 'data/graphql/types';
import {
  BookmarkList,
  CreateBookmarkOptions,
  DeleteBookmarkOptions,
} from 'types/api';
import { GetBookmarkListsDocument } from 'types/generated/api';

import BookmarkIconDisabled from 'assets/icons/ic-bookmark-false.inline.svg';
import BookmarkIconEnabled from 'assets/icons/ic-bookmark-true.inline.svg';
import NoImagePlaceholderIcon from 'assets/icons/ic-image-thumbnail.inline.svg';

import styles from './BookmarkListModalItem.module.scss';

import { GET_BOOKMARK_LISTS_MODAL_QUERY } from '../../BookmarkLists.gql';

type BookmarkListModalItemProps = {
  createBookmark: (options: CreateBookmarkOptions) => void;
  deleteBookmark: (options: DeleteBookmarkOptions) => void;
  isPublic?: boolean;
  loadingSkeleton?: boolean;
  productSid: string;
  selectedOptions: SelectedOption[];
} & BookmarkList;

const BookmarkListModalItem = ({
  bookmarks,
  coverPhoto,
  createBookmark,
  deleteBookmark,
  filteredBookmark,
  id: bookmarkListId,
  isPublic = false,
  loadingSkeleton,
  name,
  productSid,
  selectedOptions,
}: BookmarkListModalItemProps) => {
  const count = bookmarks?.pagination?.totalCount ?? 0;
  const coverPhotoUrl = coverPhoto?.url ?? '';

  const [isBookmarked, setIsBookmarked] = useState(!isEmpty(filteredBookmark));
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleBookmark = async () => {
    try {
      setIsLoading(true);
      setIsBookmarked(!isBookmarked);
      await (isBookmarked ? handleDeleteBookmark() : handleCreateBookmark());
    } catch (error) {
      setIsBookmarked(!isBookmarked);
      Logger.error('Error toggling bookmark in bookmarkListModalItem', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateBookmark = async () => {
    const createBookmarkOptions = {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetBookmarkListsDocument,
        },
        {
          query: GET_BOOKMARK_LISTS_MODAL_QUERY,
          variables: {
            filter: {
              productSid,
              selectedOptions,
            },
          },
        },
      ],
      variables: { input: { bookmarkListId, productSid, selectedOptions } },
    };
    return createBookmark(createBookmarkOptions);
  };

  const handleDeleteBookmark = () => {
    const bookmarkId = filteredBookmark?.id;
    if (bookmarkId) {
      const deleteBookmarkOptions = {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetBookmarkListsDocument,
          },
          {
            query: GET_BOOKMARK_LISTS_MODAL_QUERY,
            variables: {
              filter: {
                productSid,
                selectedOptions,
              },
            },
          },
        ],
        variables: { id: bookmarkId },
      };
      return deleteBookmark(deleteBookmarkOptions);
    } else {
      Logger.error('Bookmark id not found when deleting bookmark');
    }
  };

  const renderBookmarkCount = () => {
    if (loadingSkeleton) {
      return '';
    } else if (isLoading) {
      return 'Saving to collection...';
    }

    return `${count || 'No'} ${pluralize('items', count)} • ${
      isPublic ? 'Public' : 'Private'
    }`;
  };

  const renderBookmarkImage = () => {
    if (loadingSkeleton) {
      return null;
    } else if (!coverPhoto?.url) {
      return <NoImagePlaceholderIcon className={styles.coverPhotoIcon} />;
    }

    return (
      <img
        alt={`Cover for ${name}`}
        className={styles.coverPhoto}
        {...generateImageSrcAttributes({
          url: coverPhotoUrl,
          width: 128,
        })}
      />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.leftContainer}>
        <div className={styles.coverPhotoContainer}>
          {renderBookmarkImage()}
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <SpinnerInlineCSS size={BUTTON_SIZE.SMALL} />
            </div>
          )}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.name}>{name}</div>
          <div className={styles.bookmarkCount}>{renderBookmarkCount()}</div>
        </div>
      </div>
      {productSid && (
        <SecondaryButton
          className={styles.bookmarkIconButton}
          disabled={isLoading}
          onClick={handleToggleBookmark}
          size={BUTTON_SIZE.SMALL}
        >
          {isBookmarked ? (
            <BookmarkIconEnabled className={styles.bookmarkIcon} />
          ) : (
            <BookmarkIconDisabled className={styles.bookmarkIcon} />
          )}
        </SecondaryButton>
      )}
    </div>
  );
};

export default BookmarkListModalItem;
