import classnames from 'classnames';
import { ChangeEvent } from 'react';

import DefaultComponentProps from 'components/defaultComponentProps';

import styles from './Toggle.module.scss';

type ToggleProps = {
  isChecked: boolean;
  isSkippedFromTabNavigation?: boolean;
  labelChecked?: string;
  labelUnchecked?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
} & DefaultComponentProps;

const Toggle = ({
  className,
  isChecked,
  isSkippedFromTabNavigation,
  labelChecked,
  labelUnchecked,
  onChange,
  required,
}: ToggleProps) => (
  <div className={classnames(styles.container, className)}>
    {labelUnchecked && (
      <div className={isChecked ? styles.labelInactive : styles.labelActive}>
        {labelUnchecked}
      </div>
    )}
    <label className={styles.switch}>
      <input
        checked={isChecked}
        onChange={onChange}
        required={required}
        tabIndex={isSkippedFromTabNavigation ? -1 : 0}
        type="checkbox"
      />
      <span className={styles.slider} />
    </label>
    {labelChecked && (
      <div className={isChecked ? styles.labelActive : styles.labelInactive}>
        {labelChecked}
      </div>
    )}
  </div>
);

export default Toggle;
