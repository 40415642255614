import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import {
  ColorProps,
  SelectedOption,
  VariantFilterables,
} from 'data/graphql/types';
import { VariantAvailability } from 'types/api';
import {
  ProductPdpFragment,
  ProductVariantPdpFragment,
  ShoppingStatus,
} from 'types/generated/api';

enum ResponsibleLabels {
  CLEAN_BEAUTY = 'Clean Beauty',
  CLEAN_HOME = 'Clean Home',
}

const cleanLabelSet = new Set([
  ResponsibleLabels.CLEAN_BEAUTY,
  ResponsibleLabels.CLEAN_HOME,
]);

// Takes all selectedOptions for a variant, and creates a sorted hash
export const hashVariantSelectedOptions = (
  selectedOptions: SelectedOption[]
): string => {
  const reduced = selectedOptions.reduce(
    (arraysOfOptions: [string[], string[]], selectedOption) => {
      arraysOfOptions[0].push(selectedOption.name);
      arraysOfOptions[1].push(selectedOption.value);
      return arraysOfOptions;
    },
    [[], []]
  );

  const sortedOptionNames = compact(reduced[0]).sort();
  const sortedOptionValues = compact(reduced[1]).sort();
  return `${sortedOptionNames.join('~')}+${sortedOptionValues.join('~')}`;
};

// Looks through color options, and returns index based on the selectedColor
export const findSelectedColorIndex = (
  selectedOptions: SelectedOption[],
  colorOptions: ColorProps[]
): number | null => {
  if (colorOptions.length === 1) {
    return 0;
  }

  let selectedIndex = null;

  for (const selectedOption of selectedOptions) {
    colorOptions.map((colorOption, index) => {
      if (colorOption.value === selectedOption.value) {
        selectedIndex = index;
      }
    });
  }

  return selectedIndex;
};

export const hasCleanLabel = (filterables: VariantFilterables): boolean => {
  const responsibleLabelsArray = filterables?.responsible;

  if (!isEmpty(responsibleLabelsArray)) {
    const [cleanLabel] = responsibleLabelsArray.filter((label: string) =>
      cleanLabelSet.has(label as ResponsibleLabels)
    );

    if (cleanLabel) {
      return true;
    }
  }

  return false;
};

// Check if variant has low stock
export const isVariantLowStock = (
  variantAvailability: Array<
    Pick<
      VariantAvailability,
      'inStock' | 'isLowStock' | 'shoppingStatus' | 'sid'
    >
  >,
  selectedVariantSid?: string | null
): boolean => {
  const variantMatch = variantAvailability.find(
    variant => variant.sid === selectedVariantSid
  );
  return !!(
    variantMatch?.inStock &&
    variantMatch?.isLowStock &&
    variantMatch?.shoppingStatus === ShoppingStatus.SELLABLE
  );
};

// Check if variant has stock
/** Depricated: variants now contain their own instock data, performing a lookup for in stock data is no longer necessary */
export const isVariantInStock = (
  variantAvailability: VariantAvailability[],
  selectedVariantSid?: string | null
): boolean =>
  variantAvailability.find(variant => variant.sid === selectedVariantSid)
    ?.inStock ?? false;

/** Depricated: variants now contain their own instock data, performing a lookup for in stock data is no longer necessary */
export const isVariantStateComingSoon = (
  shoppingStatus: ShoppingStatus,
  isReplenishable: boolean,
  variantAvailability: VariantAvailability[],
  selectedVariantSid?: string | null
): boolean => {
  return (
    ShoppingStatus.COMING_SOON === shoppingStatus ||
    ShoppingStatus.ACCEPT_PREORDERS === shoppingStatus ||
    (ShoppingStatus.SELLABLE === shoppingStatus &&
      isReplenishable &&
      !isVariantInStock(variantAvailability, selectedVariantSid))
  );
};

export const isProductDiscontinued = (
  product: Pick<ProductPdpFragment, 'variants'>
): boolean => product.variants?.every(isVariantDiscontinued);

export const isVariantDiscontinued = (
  variant: Pick<
    ProductVariantPdpFragment,
    'isReplenishable' | 'inStock' | 'shoppingStatus'
  >
): boolean =>
  (!variant?.isReplenishable && !variant?.inStock) ||
  variant?.shoppingStatus === ShoppingStatus.DISCONTINUED;
