import classnames from 'classnames';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './BookmarkListModalFooter.module.scss';

type BookmarkListModalFooterProps = {
  onDoneClick: () => void;
  showTopBorder: boolean;
};

const BookmarkListModalFooter = ({
  onDoneClick,
  showTopBorder,
}: BookmarkListModalFooterProps) => {
  return (
    <div
      className={classnames(styles.root, {
        [styles.withBorder]: showTopBorder,
      })}
    >
      <PrimaryButton
        className={styles.button}
        onClick={onDoneClick}
        size={BUTTON_SIZE.MEDIUM}
        text="Done"
      />
    </div>
  );
};

export default BookmarkListModalFooter;
