import { gql } from '@apollo/client';

import { BookmarkList } from 'types/api';

export const GET_BOOKMARKS_BY_PRODUCT_SIDS = gql`
  query productBookmarksBySids($sids: [String]!) {
    me {
      id
      productBookmarksBySids(sids: $sids) {
        productBookmarks {
          bookmarkList {
            id
            name
          }
          id
          selectedOptions {
            name
            value
          }
        }
        productSid
      }
    }
  }
`;

export type BookmarksByProductSidResponse = {
  me: {
    bookmarkLists?: {
      items: BookmarkList[];
    };
    productBookmarksBySids: Array<{
      productBookmarks: ProductBookmark[];
      productSid: string;
    }>;
  };
};

export type ProductBookmark = {
  bookmarkList: {
    id: string;
    name: string;
  };
  id: string;
  productSid: string;
  selectedOptions: Array<{ name: string; value: string }>;
};

export const GET_CUSTOMER_REVIEWS = gql`
  query getCustomerReviews($productSid: String!, $page: Int, $pageSize: Int) {
    customerReviewsPage(
      productSid: $productSid
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        currentPage
        numberOfPages
      }
      reviews {
        comment {
          content
          createdAt
          id
        }
        content
        id
        imagesData {
          id
          thumbUrl
          originalUrl
        }
        reviewDate
        score
        sourceName
        sourceUrl
        title
        user {
          displayName
        }
        verifiedBuyer
      }
      summary {
        averageScore
        ratingDistribution {
          count
          score
        }
        totalReview
      }
    }
  }
`;
