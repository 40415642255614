import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import AddFilledIcon from 'assets/icons/ic-add-filled.inline.svg';

import styles from './CreateNewCollectionModalItem.module.scss';

type CreateNewCollectionModalItemProps = {
  onCreateNewCollection: () => void;
};

export const CreateNewCollectionModalItem = ({
  onCreateNewCollection,
}: CreateNewCollectionModalItemProps) => {
  return (
    <div
      className={styles.container}
      onClick={onCreateNewCollection}
      onKeyPress={keyPressHandler({
        Enter: onCreateNewCollection,
      })}
      role="button"
      tabIndex={0}
    >
      <AddFilledIcon className={styles.icon} />
      <div className={styles.text}>Create a Collection</div>
    </div>
  );
};
