import classnames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './AlertModal.module.scss';

export enum AnimationInFrom {
  BottomLeft = 'animateFromBottomLeft',
  TopRight = 'animateFromTopRight',
}

type AlertModalProps = {
  animationInFrom?: AnimationInFrom;
  autoCloseInterval?: number;
  children?: ReactNode;
  classNameRoot?: string;
  closeModal: () => void;
  isOpen: boolean;
};

const AUTO_CLOSE_INTERVAL = 4000;
const CLOSE_ANIMATION_DELAY = 700;

const AlertModal = ({
  animationInFrom = AnimationInFrom.TopRight,
  autoCloseInterval = AUTO_CLOSE_INTERVAL,
  children = null,
  classNameRoot,
  closeModal,
  isOpen,
}: AlertModalProps) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen && !isVisible) {
      setIsVisible(true);
      setAnimateIn(true);

      setTimeout(() => {
        handleCloseModal();
      }, autoCloseInterval);
    }
  });

  const handleCloseModal = () => {
    setAnimateIn(false);

    setTimeout(() => {
      closeModal();
      setIsVisible(false);
    }, CLOSE_ANIMATION_DELAY);
  };

  if (!isOpen && !isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classnames(
        styles.root,
        {
          [styles.animate]: animateIn,
          [styles.animateFromBottomLeft]:
            animationInFrom === AnimationInFrom.BottomLeft,
          [styles.animateFromTopRight]:
            animationInFrom === AnimationInFrom.TopRight,
        },
        classNameRoot
      )}
    >
      <div
        className={styles.closeButton}
        onClick={handleCloseModal}
        onKeyPress={keyPressHandler({ Enter: handleCloseModal })}
        role="button"
        tabIndex={0}
      >
        <CloseIcon />
      </div>
      {children}
    </div>,
    document.body as Element
  );
};

export default AlertModal;
