import { gql } from '@apollo/client';

export const CREATE_BOOKMARK_LIST_MUTATION = gql`
  mutation createBookmarkList($input: BookmarkListInput!) {
    createBookmarkList(input: $input) {
      bookmarkList {
        id
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

export const DELETE_BOOKMARK_LIST_MUTATION = gql`
  mutation deleteBookmarkList($id: ID!) {
    deleteBookmarkList(id: $id) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

export const GET_BOOKMARK_LISTS_QUERY = gql`
  query getBookmarkLists($cursor: String, $limit: Int) {
    me {
      bookmarkLists(cursor: $cursor, limit: $limit) {
        items {
          id
          bookmarks(bookmarkType: ALL, limit: 3) {
            items {
              ... on ProductBookmark {
                product {
                  variants {
                    galleryAssets {
                      type
                      url
                    }
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
                selectedOptions {
                  name
                  value
                }
              }
              ... on ExplorerPostBookmark {
                explorerPost {
                  assets {
                    type
                    url
                  }
                }
              }
              type
            }
            pagination {
              totalCount
            }
          }
          name
          public
          slug
        }
        pagination {
          cursor
          hasMore
          totalCount
        }
      }
      id
    }
  }
`;

export const GET_BOOKMARK_LISTS_MODAL_QUERY = gql`
  query getBookmarkListsModal($filter: ProductBookmarkInput!) {
    me {
      bookmarkLists {
        items {
          bookmarks {
            pagination {
              totalCount
            }
          }
          coverPhoto {
            url
          }
          filteredBookmark(filter: $filter) {
            id
          }
          id
          name
          public
          slug
        }
      }
      id
    }
  }
`;

export const UPDATE_BOOKMARK_LIST_MUTATION = gql`
  mutation updateBookmarkList($input: UpdateBookmarkListInput!) {
    updateBookmarkList(input: $input) {
      bookmarkList {
        id
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;
