import { MouseEvent, ReactElement } from 'react';

import SecondaryButton from 'components/Buttons/SecondaryButton';
import AlertModal, {
  AnimationInFrom,
} from 'components/Modals/AlertModal/AlertModal';

import styles from './BookmarkAlertModal.module.scss';

type BookmarkAlertModalProps = {
  closeModal: () => void;
  isBookmarked: boolean;
  isOpen: boolean;
  listTitle?: string;
  loading: boolean;
  onChangeClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

const BookmarkAlertModal = ({
  closeModal,
  isBookmarked,
  isOpen,
  listTitle = 'My Wishlist',
  loading,
  onChangeClick,
}: BookmarkAlertModalProps): ReactElement => (
  <AlertModal
    animationInFrom={AnimationInFrom.BottomLeft}
    classNameRoot={styles.alertModalRoot}
    closeModal={closeModal}
    isOpen={isOpen}
  >
    <div className={styles.root}>
      <div aria-live="polite" className={styles.text} role="status">
        {`${
          isBookmarked && !loading ? 'Added to ' : 'Removed from collection'
        }`}
        {isBookmarked && !loading && (
          <span className={styles.bookmarkText}>
            {listTitle || 'collection'}
          </span>
        )}
      </div>
      {isBookmarked && !loading && (
        <SecondaryButton
          className={styles.changeButton}
          onClick={onChangeClick}
        >
          Change
        </SecondaryButton>
      )}
    </div>
  </AlertModal>
);

export default BookmarkAlertModal;
