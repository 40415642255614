import { gql } from '@apollo/client';

export const CREATE_BOOKMARK_MUTATION = gql`
  mutation createProductBookmark($input: ProductBookmarkInput!) {
    createProductBookmark(input: $input) {
      bookmark {
        id
        ... on ProductBookmark {
          bookmarkList {
            id
            name
          }
          id
          selectedOptions {
            name
            value
          }
        }
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

export const DELETE_BOOKMARK_MUTATION = gql`
  mutation deleteBookmarkFromBookmarkButton($id: ID!) {
    deleteBookmark(id: $id) {
      id
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

export type CreateBookmarkResponse = {
  createProductBookmark: {
    bookmark: Bookmark;
  };
};

export type DeleteBookmarkResponse = {
  deleteBookmark: {
    id: string;
  };
};

export type Bookmark = {
  bookmarkList: BookmarkList;
  id: string;
  selectedOptions: SelectedOption[];
};

export type BookmarkList = {
  id: string;
  name: string;
};

export type SelectedOption = {
  name: string;
  value: string;
};
