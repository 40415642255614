import HomeIcon from 'assets/icons/ic-clean-beauty.inline.svg';

import styles from './CleanTag.module.scss';

type CleanTagProps = {
  hideText?: boolean;
};

const CleanTag = ({ hideText = false }: CleanTagProps) => (
  <div className={styles.root}>
    <div className={styles.badge}>
      <HomeIcon />
    </div>
    {!hideText && <div className={styles.text}>Clean</div>}
  </div>
);

export default CleanTag;
